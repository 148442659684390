<template>
  <div>
	<commonTitle v-bind:datas="datas"></commonTitle>
	<cardTab :listArray="list" :current="current" @changeTab="changeTab"></cardTab>
	<!-- 储能方案 -->
	<!-- 家用储能 -->
	<div v-show="current == -1" class="clearfix pab60  animated">
	  <div class="container mgt60 card-two_">
	    <div class="cdo-right fl animated">
	      <img src="@/assets/images/sysindex/jiayong.png"
	           alt=""
	           style="height:362px!important" />
	    </div>
	    <div class="fl cdo-left animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
	        <p class="font_24 font_b cdol-title" style="color:#268A42;">室内储能解决方案</p>
			<p class="font_18 mgt10 font_b" style="color: #555;">方案介绍:</p>
	        <p class="font_10 mgt6 cdol-desc" style="line-height: 28px">
	          &nbsp;&nbsp;&nbsp;&nbsp;
			  忆能科技为客户提供一套室内储能系统完全集成
			  交付的储能解决方案。该解决方案是具有内置能源
			  管理系统的一站式集成系统；模块化和可扩展设计；
			  并通过认证：UL950、CA CEC、UL1741-SA。
			</p>
			<p class="font_18 font_b" style="color: #555;">方案优势:</p>
			<div style="display:inline-block; width: 25%; margin-top: 15px;">
				<p class="circle_green"><img src="@/assets/resolution/duliyunxing.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  独立运行
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/linghuokuozhan.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  灵活扩展
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/jieshengshijian.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  节省时间
				</p>
			</div>
			
	      </div>
	    </div>
	  </div>
	</div>
	<!-- 工商业 -->
	<div v-show="current == 0" class="clearfix pab60  animated">
	  <div class="container mgt60 card-two_">
	    <div class="cdo-right fl animated">
	      <img src="@/assets/images/sysindex/gongshangye.jpg"
	           alt=""
	           style="position:relative;top: 6%;max-height:400px!important" />
	    </div>
	    <div class="fl cdo-left animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
	        <p class="font_24 font_b cdol-title" style="color:#268A42;">工商业储能解决方案</p>
			<p class="font_18 mgt10 font_b" style="color: #555;">方案介绍:</p>
			<p class="font_10 mgt6 cdol-desc" style="line-height: 28px">
			  &nbsp;&nbsp;&nbsp;&nbsp;
			  针对工商业储能，为客户提供户外柜、10尺及20尺集装箱一体化储能解决方案，
			  方案采用分支路组串式架构，实现电池组一簇一管理。内置磷酸铁锂电池组，储能变流器，
			  空调热管理系统，消防系统，配电，及能量管理系统。具有占地面积小、运输方便、
			  环境适应能力强、建设周期短、智能化程度高等优点，可实现用电交流端“即接即用”，
			  高效解决客户在削峰填谷、需量管理、需求响应、光充储结合、后备电源等应用领域的需求。
			  系统满足UL9540，UN3536，NFPA855标准。
			</p>
			<p class="font_18 font_b" style="color: #555;">方案优势:</p>
			<div style="display:inline-block; width: 25%; margin-top: 15px;">
				<p class="circle_green"><img src="@/assets/resolution/gaoanquanxing.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  高安全性
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/jingjigaoxiao.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  经济高效
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/bushulinghuo.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  部署灵活
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/yunweibianjie.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  运维便捷
				</p>
			</div>
	      </div>
	    </div>
	  </div>
	</div>
	<!-- 电网侧储能 -->
	<div v-show="current == 1" class="clearfix pab60  animated">
	  <div class="container mgt60 card-two_">
	    <div class="cdo-right fl animated">
	      <img src="@/assets/images/sysindex/dianwangce.jpg"
	           alt=""
	           style="position:relative;top: 10%; width:580px; !important;" />
	    </div>
	    <div class="fl cdo-left animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
	        <p class="font_24 font_b cdol-title" style="color:#268A42;">电网侧储能解决方案</p>
			<p class="font_18 mgt10 font_b" style="color: #555;">方案介绍:</p>
			<p class="font_10 mgt6 cdol-desc" style="line-height: 28px">
			  &nbsp;&nbsp;&nbsp;&nbsp;
			  针对电网侧储能，为客户提供40尺集装箱式一体化储能解决方案，方案采用分支路组串式架构，
			  实现电池组一簇一管理。内置磷酸铁锂电池组，储能变流器，空调热管理系统，消防系统，配电，
			  及能量管理系统。具有占地面积小、运输方便、环境适应能力强、建设周期短、智能化程度高等优点，
			  可满足在电网调频、调峰、风光储结合等领域的应用需求，保障电网安全、可靠、稳定运行。
			  系统满足UL9540，UN3536，NFPA855标准。
			</p>
			<p class="font_18 font_b" style="color: #555;">方案优势:</p>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/gaoanquanxing.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  安全性高
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/haomiaojixiangying.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  毫秒级响应
				</p>
			</div>
			<div style="display:inline-block; width: 25%; margin-top: 15px;">
				<p class="circle_green"><img style="height: 80%;margin-left: 8px;" src="@/assets/resolution/yunduanjiankong.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  智能监控
				</p>
			</div>
	      </div>
	    </div>
	  </div>
	</div>
	<!-- 独立电池方案 -->
	<div v-show="current == 2"  class="clearfix pab60  animated">
	  <div class="container mgt60 card-two_">
	    <div class="cdo-right fl animated">
	      <img src="@/assets/images/sysindex/standAloneBat.png"
	           alt=""
	           style="position:relative;top: 10%;height:322px!important" />
	    </div>
	    <div class="fl cdo-left animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
	        <p class="font_24 font_b cdol-title" style="color:#268A42;">独立电池储能解决方案</p>
			<p class="font_18 mgt10 font_b" style="color: #555;">方案介绍:</p>
			<p class="font_10 mgt6 cdol-desc" style="line-height: 28px">
			  &nbsp;&nbsp;&nbsp;&nbsp;
			  忆能提供一款直流输出48V的后备电池系统，系统内置有智能BMS，可自主实现电池组状态监控、
			  充放电管理、故障诊断及保护等功能。单包电量11kWh，支持多包并联扩展以满足客户对不同电量的需求。
			  支持客户在多种应用场合DIY多种应用方案，比如客户可以考虑将此直流电池系统用作“太阳能应急照明车”
			  的储能电池组，外围搭配光伏发电配件+充电/逆变一体机，即可实现白天通过光伏发电对电池组进行充电，
			  夜间电池组放电供LED进行应急照明，太阳能不足时也可以利用市电对应急照明车进行充电，同时也可在需
			  要时通过逆变输出交流电供离网负载使用。
			</p>
			<p class="font_18 font_b" style="color: #555;">方案优势:</p>
			<div style="display:inline-block; width: 25%; margin-top: 15px;">
				<p class="circle_green"><img style="height: 90%;margin-top: 5px;margin-left: 2px;" src="@/assets/resolution/dianxinshouming.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  电芯寿命长
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/duli-linghuotuozhan.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  灵活拓展
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img style="height: 90%;margin-left: 4px;margin-top: 5px;" src="@/assets/resolution/duli-zhinengbaohu.png" /></p>
				<p class="font_12 mgt6 cdol-desc">
				  智能保护
				</p>
			</div>
	      </div>
	    </div>
	  </div>
	</div>
	<!-- 智慧均衡方案 -->
	<div v-show="current == 3" class="clearfix pab60  animated">
	  <div class="container mgt60 card-two_">
	    <div class="cdo-right fl mgt20 animated">
	      <img src="@/assets/images/sysindex/zhihui_balance_1.png"
	           alt=""
	           style="position:relative;top: 10%;height:332px!important" />
	    </div>
		<!-- <div class="cdo-right fl animated">
		  <img src="@/assets/images/sysindex/zhihui_balance_2.png"
				alt=""
				style="height:362px!important" />
		</div> -->
	    <div class="fl cdo-left animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
	        <p class="font_24 font_b cdol-title" style="color:#268A42;">智慧均衡方案</p>
			<p class="font_18 mgt10 font_b" style="color: #555;">产品介绍:</p>
	        <p class="font_10 mgt6 cdol-desc" style="line-height: 28px">
	          &nbsp;&nbsp;&nbsp;&nbsp;
			  市场主流储能系统采用被动均衡，系统运行时间越长，
			  电芯差异性越大，通常数月后，需派专人去现场拆电池包补电维护。
			  忆能推出的智能维护设备YINENG Battery Balance Unit可兼容市场主流BMS，
			  该设备实现了智能维护、精准补电，可将电池系统各电芯差异控制在5mV以内，且安全便捷，操作简单。
			</p>
			<p class="font_18 font_b" style="color: #555;">方案优势:</p>
			<div style="display:inline-block; width: 25%;margin-top: 15px;">
				<p  class="circle_green"><img src="@/assets/resolution/jingzhunjiengque.png" /></p>
				<p class="font_12  cdol-desc">
				  智能算法
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img style="height: 80%; margin-top: 8px;margin-left: 8px;" src="@/assets/resolution/yunjiankong.png" /></p>
				<p class="font_12  cdol-desc">
				  双重监控
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img style="height: 80%;margin-top: 8px;margin-left: 8px;" src="@/assets/resolution/weihuchenbendi.png" /></p>
				<p class="font_12 cdol-desc">
				  维护成本低
				</p>
			</div>
			<div style="display:inline-block; width: 25%;">
				<p class="circle_green"><img src="@/assets/resolution/gaoanquanxing-1.png" /></p>
				<p class="font_12  cdol-desc">
				  高安全性
				</p>
			</div>
	      </div>
	    </div>
	  </div>
	</div>
	<!-- 方案图 -->
    <div v-show="showFangan()" class="clearfix pab60">
      <div class="container mgt60 card-one">
        <div class="fl cdo-left animated"
             data-animation="fadeInLeft"
             animation-status="true"
             style="
            visibility: visible;
            animation-name: fadeInLeft;
            animation-delay: 0s;
          ">
          <div class="cdol-top">
			  <p class="font_28 mgt40">系统示意图</p>
			  <div class="b_border"></div>
          </div>
			<img class="mgt60" v-show="current == -1" src="@/assets/resolution/shinei-shiyi.png" alt="" />
			<img class="mgt60" v-show="current == 0" src="@/assets/resolution/gongsy-shiyi.png" alt="" />
			<img class="mgt60" v-show="current == 1" src="@/assets/resolution/dianw-shiyi.png" alt="" />
			<!-- <img class="mgt60" v-show="current == 2" src="@/assets/resolution/shinei-shiyi.png" alt="" /> -->
        </div>
		
        <div class="cdo-right animated"
             data-animation="fadeInRight"
             animation-status="true"
             style="
            visibility: visible;
            animation-name: fadeInRight;
            animation-delay: 0s;
          ">
          <!-- <img src="@/assets/images/sysindex/card1.png"
               alt="" /> -->
        </div>
      </div>
    </div>
	<!-- 独立电池使用场景 -->
	<div v-show="current == 2" class="clearfix pab60">
	  <div class="container mgt60 card-one">
	    <div class="fl cdo-left animated"
	         data-animation="fadeInLeft"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInLeft;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
			  <p class="font_28 mgt40">使用场景</p>
			  <div class="b_border"></div>
	      </div>
		  <div style="display: inline-block;">
			  <img class="mgt60"  src="@/assets/resolution/dulidianchi-changjing1.png" alt="" />
			  <div style="width: 20px;display: inline-block;"></div>
			  <img class="mgt60" style="height: 353px;width: 529px;" src="@/assets/resolution/dulidianchi-changjing2.png" alt="" />
		  </div>
			
	    </div>
		
	    <div class="cdo-right animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <!-- <img src="@/assets/images/sysindex/card1.png"
	           alt="" /> -->
	    </div>
	  </div>
	</div>
	<!-- 均衡装置 -->
	<div v-show="current == 3" class="clearfix pab60">
	  <div class="container mgt60 card-one">
	    <div class="fl cdo-left animated"
	         data-animation="fadeInLeft"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInLeft;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
			  <p class="font_28 mgt40">上位机软件</p>
			  <div class="b_border"></div>
	      </div>
			<img class="mgt60" src="@/assets/resolution/zhihui_balance-shiyi.png" alt="" />
	    </div>
		
	    <div class="cdo-right animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <!-- <img src="@/assets/images/sysindex/card1.png"
	           alt="" /> -->
	    </div>
	  </div>
	</div>
	<!-- 系统规格 -->
	<div class="clearfix pab60">
	  <div class="container mgt60 card-one">
	    <div class="fl cdo-left animated"
	         data-animation="fadeInLeft"
	         animation-status="true"
	         style="
			width: 100%;
	        visibility: visible;
	        animation-name: fadeInLeft;
	        animation-delay: 0s;
	      ">
	      <div class="cdol-top">
			  <p class="font_28 mgt40">系统规格</p>
			  <div class="b_border"></div>
	      </div>
		  <!-- table list -->
		  <!-- 室内 -->
		  <div v-show="current == -1" class="mgt60" style="width: 100%;">
		    <div class="plan_box">
		  	  <table>
		  		  <thead>
		  			  <td class="head" style="width: 50PX;">NO.</td>
		  			  <td colspan="2" class="head" style="width: 45%;">参数名</td>
		  			  <td class="head" style="width: 45%;">L30xx系列</td>
		  		  </thead>
		  		  <tbody>
		  			  <tr>
		  				  <td>1</td>
		  				  <td colspan="2">额定能量</td>
		  				  <td>66kWh…143kWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td>2</td>
		  				  <td colspan="2">额定电压</td>
		  				  <td>307VdC…665Vdc</td>
		  			  </tr>
		  			  <tr>
		  				  <td>3</td>
		  				  <td colspan="2">工作电压范围</td>
		  				  <td>200~750Vdc</td>
		  			  </tr>
		  			  <tr>
		  				  <td>4</td>
		  				  <td colspan="2">大功率</td>
		  				  <td>30kW</td>
		  			  </tr>
		  			  <tr>
		  				  <td>5</td>
		  				  <td colspan="2">工作环境温度</td>
		  				  <td>充电：0~55℃
		  					放电：-20~55℃</td>
		  			  </tr>
		  			  <tr>
		  				  <td>6</td>
		  				  <td colspan="2">热管理方式</td>
		  				  <td>空调制冷/加热</td>
		  			  </tr>
		  			  <tr>
		  				  <td>7</td>
		  				  <td colspan="2">寿命</td>
		  				  <td>4500 Cycle</td>
		  			  </tr>
		  			  <tr>
		  				  <td rowspan="3">8</td>
		  				  <td rowspan="3">配置</td>
		  				  <td >电芯</td>
		  				  <td>3.2V/27Ah</td>
		  			  </tr>
		  			  <tr>
		  				  <td >电池箱</td>
		  				  <td>51.2V / 216Ah，11kWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td >电池簇</td>
		  				  <td>电池箱*n+1主控箱</td>
		  			  </tr>
		  			  <tr>
		  				  <td>9</td>
		  				  <td colspan="2">通信</td>
		  				  <td>CAN，LAN</td>
		  			  </tr>
		  			  <tr>
		  				  <td>10</td>
		  				  <td colspan="2">均衡方式</td>
		  				  <td>被动均衡</td>
		  			  </tr>
		  			  <tr>
		  				  <td rowspan="3">11</td>
		  				  <td rowspan="3">PCS</td>
		  				  <td>额定电压</td>
		  				  <td>380Vac</td>
		  			  </tr>
		  			  <tr>
		  				  <td>频率</td>
		  				  <td>50HZ/60HZ</td>
		  			  </tr>
		  			  <tr>
		  				  <td>PCS效率</td>
		  				  <td>97.5%</td>
		  			  </tr>
		  			  <tr>
		  				  <td>12</td>
		  				  <td colspan="2">系统效率</td>
		  				  <td>89%</td>
		  			  </tr>
		  			  <tr>
		  				  <td>13</td>
		  				  <td colspan="2">尺寸</td>
		  				  <td>600*1000*2000 /
		  					1200*1000*2000
		  					</td>
		  			  </tr>
		  			  <tr>
		  				  <td>14</td>
		  				  <td colspan="2">重量</td>
		  				  <td>850kg…1500kg</td>
		  			  </tr>
		  		  </tbody>
		  	  </table>
		    </div>
		  </div>
		  <!-- 室外工商业 -->
		  <div v-show="current == 0" class="mgt60" style="width: 100%;">
		    <div class="plan_box">
		  	  <table >
		  		  <thead>
		  			  <td class="head" style="width: 50PX;">NO.</td>
		  			  <td colspan="2" class="head" style="width: 30%;">参数名</td>
		  			  <td class="head" style="width: 30%;">20尺</td>
		  			  <td class="head" style="width: 30%;">10尺</td>
		  			  <!-- <td class="head">提交时间</td> -->
		  		  </thead>
		  		  <tbody>
		  			  <tr>
		  				  <td>1</td>
		  				  <td colspan="2">额定能量</td>
		  				  <td>1.32MWh</td>
		  				  <td>495kWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td>2</td>
		  				  <td colspan="2">额定电压</td>
		  				  <td>768Vdc</td>
		  				  <td>768Vdc</td>
		  			  </tr>
		  			  <tr>
		  				  <td>3</td>
		  				  <td colspan="2">工作电压范围</td>
		  				  <td>696~852Vdc</td>
		  				  <td>696~852Vdc</td>
		  			  </tr>
		  			  <tr>
		  				  <td>4</td>
		  				  <td colspan="2">大功率</td>
		  				  <td>500kW</td>
		  				  <td>250kW</td>
		  			  </tr>
		  			  <tr>
		  				  <td>5</td>
		  				  <td colspan="2">工作环境温度</td>
		  				  <td>充电：0~55℃
		  					放电：-20~55℃</td>
		  				  <td>充电：0~55℃
		  				  放电：-20~55℃</td>
		  			  </tr>
		  			  <tr>
		  				  <td>6</td>
		  				  <td colspan="2">热管理方式</td>
		  				  <td>空调制冷/加热</td>
		  				  <td>空调制冷/加热</td>
		  			  </tr>
		  			  <tr>
		  				  <td>7</td>
		  				  <td colspan="2">寿命</td>
		  				  <td>4500 Cycle</td>
		  				  <td>4500 Cycle</td>
		  			  </tr>
		  			  <tr>
		  				  <td rowspan="4">8</td>
		  				  <td rowspan="4">配置</td>
		  				  <td >电芯</td>
		  				  <td>3.2V/27Ah</td>
		  				  <td>3.2V/27Ah</td>
		  			  </tr>
		  			  <tr>
		  				  <td >电池箱</td>
		  				  <td>51.2V/216Ah,11kWh</td>
		  				  <td>51.2V/216Ah,11kWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td >电池簇</td>
		  				  <td>768V,165kWh</td>
		  				  <td>768V,165kWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td >系统</td>
		  				  <td>165kWh * 8</td>
		  				  <td>165kWh * 3</td>
		  			  </tr>
		  			  <tr>
		  				  <td>9</td>
		  				  <td colspan="2">通信</td>
		  				  <td>CAN，LAN</td>
		  				  <td>CAN，LAN</td>
		  			  </tr>
		  			  <tr>
		  				  <td>10</td>
		  				  <td colspan="2">均衡方式</td>
		  				  <td>被动均衡</td>
		  				  <td>被动均衡</td>
		  			  </tr>
		  			  <tr>
		  				  <td rowspan="3">11</td>
		  				  <td rowspan="3">PCS</td>
		  				  <td>额定电压</td>
		  				  <td>380Vac</td>
		  				  <td>380Vac</td>
		  			  </tr>
		  			  <tr>
		  				  <td>频率</td>
		  				  <td>50HZ/60HZ</td>
		  				  <td>50HZ/60HZ</td>
		  			  </tr>
		  			  <tr>
		  				  <td>PCS效率</td>
		  				  <td>97.5%</td>
		  				  <td>97.5%</td>
		  			  </tr>
		  			  <tr>
		  				  <td>12</td>
		  				  <td colspan="2">系统效率</td>
		  				  <td>89%</td>
		  				  <td>89%</td>
		  			  </tr>
		  			  <tr>
		  				  <td>13</td>
		  				  <td colspan="2">尺寸</td>
		  				  <td>6058*2438*2591</td>
		  				  <td>2991*2438*2591 </td>
		  			  </tr>
		  			  <tr>
		  				  <td>14</td>
		  				  <td colspan="2">重量</td>
		  				  <td>21吨</td>
		  				  <td>9.5吨</td>
		  			  </tr>
		  		  </tbody>
		  	  </table>
		    </div>
		  </div>
		  <!-- 电网测 -->
		  <div v-show="current == 1" class="mgt60" style="width: 100%;">
		    <div class="plan_box">
		  	  <table>
		  		  <thead>
		  			  <td class="head" style="width: 50PX;">NO.</td>
		  			  <td colspan="2" class="head" style="width: 30%;">参数名</td>
		  			  <td class="head" style="width: 30%;">40尺-1</td>
		  			  <td class="head" style="width: 30%;">40尺-2</td>
		  			  <!-- <td class="head">提交时间</td> -->
		  		  </thead>
		  		  <tbody>
		  			  <tr>
		  				  <td>1</td>
		  				  <td colspan="2">额定能量</td>
		  				  <td>2.64MWh</td>
		  				  <td>4.9MWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td>2</td>
		  				  <td colspan="2">额定电压</td>
		  				  <td>768Vdc</td>
		  				  <td>768Vdc</td>
		  			  </tr>
		  			  <tr>
		  				  <td>3</td>
		  				  <td colspan="2">工作电压范围</td>
		  				  <td>696~852Vdc</td>
		  				  <td>696~852Vdc</td>
		  			  </tr>
		  			  <tr>
		  				  <td>4</td>
		  				  <td colspan="2">大功率</td>
		  				  <td>2MW</td>
		  				  <td>2MW</td>
		  			  </tr>
		  			  <tr>
		  				  <td>5</td>
		  				  <td colspan="2">工作环境温度</td>
		  				  <td>充电：0~55℃
		  				  放电：-20~55℃</td>
		  				  <td>充电：0~55℃
		  				  放电：-20~55℃</td>
		  			  </tr>
		  			  <tr>
		  				  <td>6</td>
		  				  <td colspan="2">热管理方式</td>
		  				  <td>空调制冷/加热</td>
		  				  <td>空调制冷/加热</td>
		  			  </tr>
		  			  <tr>
		  				  <td>7</td>
		  				  <td colspan="2">寿命</td>
		  				  <td>4500 Cycle</td>
		  				  <td>4500 Cycle</td>
		  			  </tr>
		  			  <tr>
		  				  <td rowspan="4">8</td>
		  				  <td rowspan="4">配置</td>
		  				  <td >电芯</td>
		  				  <td>3.2V/27Ah</td>
		  				  <td>3.2V/100Ah</td>
		  			  </tr>
		  			  <tr>
		  				  <td >电池箱</td>
		  				  <td>51.2V/216Ah,11kWh</td>
		  				  <td>51.2V/400Ah,20kWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td >电池簇</td>
		  				  <td>768V,165kWh</td>
		  				  <td>768V,307kWh</td>
		  			  </tr>
		  			  <tr>
		  				  <td >系统</td>
		  				  <td>165kWh * 16</td>
		  				  <td>307kWh * 16</td>
		  			  </tr>
		  			  <tr>
		  				  <td>9</td>
		  				  <td colspan="2">通信</td>
		  				  <td>CAN，LAN</td>
		  				  <td>CAN，LAN</td>
		  			  </tr>
		  			  <tr>
		  				  <td>10</td>
		  				  <td colspan="2">均衡方式</td>
		  				  <td>被动均衡</td>
		  				  <td>被动均衡</td>
		  			  </tr>
		  			  <tr>
		  				  <td rowspan="3">11</td>
		  				  <td rowspan="3">PCS</td>
		  				  <td>额定电压</td>
		  				  <td>380Vac</td>
		  				  <td>380Vac</td>
		  			  </tr>
		  			  <tr>
		  				  <td>频率</td>
		  				  <td>50HZ/60HZ</td>
		  				  <td>50HZ/60HZ</td>
		  			  </tr>
		  			  <tr>
		  				  <td>PCS效率</td>
		  				  <td>97.5%</td>
		  				  <td>97.5%</td>
		  			  </tr>
		  			  <tr>
		  				  <td>12</td>
		  				  <td colspan="2">系统效率</td>
		  				  <td>89%</td>
		  				  <td>89%</td>
		  			  </tr>
		  			  <tr>
		  				  <td>13</td>
		  				  <td colspan="2">尺寸</td>
		  				  <td>12192×2438×2896mm</td>
		  				  <td>12192×2438×2896mm</td>
		  			  </tr>
		  			  <tr>
		  				  <td>14</td>
		  				  <td colspan="2">重量</td>
		  				  <td>41吨</td>
		  				  <td>53吨</td>
		  			  </tr>
		  		  </tbody>
		  	  </table>
		    </div>
		  </div>
		</div>
	    <div class="cdo-right animated"
	         data-animation="fadeInRight"
	         animation-status="true"
	         style="
	        visibility: visible;
	        animation-name: fadeInRight;
	        animation-delay: 0s;
	      ">
	      <!-- <img src="@/assets/images/sysindex/card1.png"
	           alt="" /> -->
	    </div>
	  </div>
	</div>
	
  </div>
</template>
<script>
import commonTitle from '@/components/common/commonTitle.vue'
import cardTab from '@/components/common/cardTab.vue'
export default {
  name: 'Product',
	components: {
		commonTitle,
		cardTab,
	},
  data() {
    return {
	  current: 0,
	  list: [
		// { id: 4, name: "室内储能方案" },
		{ id: 0, name: "工商业储能方案" },
		{ id: 1, name: "电网侧储能方案" },
		{ id: 2, name: "独立电池方案" },
		{ id: 3, name: "智慧均衡方案" },
	  ],
	  datas:["solution"],
	  // 解决方案详情展示
	  indoorShow:false,
	  outdoorShow:false,
	  gridShow:false,
	  standAloneBatShow:false,
	  
	  isshow:false,
	  showButton:true,
      showOne: false,
      showTwo: false,
      showThree: false,
      showFour: false,
    }
  },
  methods: {
	showFangan(){
		switch (this.current) {
			case 0:
				return true
			case 1:
				return true
			case 2:
				return false
			case 3:
				return false
			case 4:
			
		}
	},
	// 切换选项卡
	changeTab(tab) {
	  this.current = tab.id;
	},
	checkSolotionDetail(stype,isbool) {
		switch(stype){
			case "indoor":
				this.indoorShow = isbool;
				// window.open(require('@/assets/resolution/'));
				break;
			case "outdoor":
				this.outdoorShow = isbool;
				break;
			case "grid":
				this.gridShow = isbool;
				break;
			case "standAloneBat":
				this.standAloneBatShow = isbool;
				break;
		}
	},
	checkmore(b) {
		this.isshow = b;
		this.showButton = !b;
	},
  },
  mounted() {
  	window.scrollTo(0, 0)
  },
}
</script>
<style lang="scss">
@import '@/assets/css/business.scss';

.b_border {
    background: #2f73f8;
    height: 3px;
    width: 40px;
    margin-top: 14px
}
.selfbutton {  
        width: 160px;  
        padding:8px;   
		border: 1px solid transparent;
        border-color: #044c26;
        color: #044c26;  
		background-color: #FFFFFF; 
        -moz-border-radius: 10px;  
        -webkit-border-radius: 10px;  
        border-radius: 10px; /* future proofing */  
        -khtml-border-radius: 10px; /* for old Konqueror browsers */  
        text-align: center;  
        vertical-align: middle;  
        font-weight: 700;  
        font-size:120%  
      }  
.selfbutton:hover {
	background-color: #5cb85c; 
	color:  #FFFFFF;
}
.selfbutton-pos-30 {
	position: relative;
	top: 30px;
}
.selfbutton-pos-20 {
	position: relative;
	top: 0px;
}

.circle_green {
	/*background: burlywood;*/
	border: 1px solid #06720c;
	// color: #000;
	width: 70px;
	height: 70px;
	border-radius: 50%;
}

.plan_box {
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.plan_box table {
  border-spacing: 1;
  width: 100%;
}
.plan_box table thead tr:nth-child(2n+1) {
  background-color: #E9F1F9;
}
.plan_box table tr {
  height: 35px;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
  font-weight: 400;
}
.plan_box table tr:nth-child(2n) {
  background-color: #D0E1F2;
}
.plan_box table .head {
  font-size: 14px;
  color: #666666;
  background-color: #4EA6DC;
  line-height: 14px;
  font-weight: 600;
}
</style>
