<template>
  <div class="header" ref="hhh">
    <div class="header_tab" ref="headertab">
      <ul ref="tabitem">
        <li
          v-for="(item, index) in listArray"
          :key="index"
          :class="index == current ? 'activeheader' : ''"
          @click="changeTab(index, $event, item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "cardTab",
  props: {
    current: Number,
    listArray: {
      type: Array,
    },
  },
  data() {
    return {
		flex_type:	'center'
	};
  },
  methods: {
    changeTab(index, e, item) {
      this.$emit("changeTab", item);
      // this.current = index; // 高亮当前
      let tab = this.$refs.headertab; // 包裹 ul的 div
      let tabitem = this.$refs.tabitem; // 包裹 li的 ul
      let winWidth = this.$refs.hhh.clientWidth; // 当前屏幕的宽度
      let liList = e.target; // 当前点击的li
      if (liList) {
        //  当前li左偏移, li的宽度, 中间值(当前屏幕的宽度 - li的宽度) /2, 目标值 (中间值 - 当前li左偏移), 整个ul的宽度
        let liLeft = liList.offsetLeft,
          liWidth = liList.offsetWidth,
          liCenter = (winWidth - liWidth) / 2,
          liTarget = liLeft - liCenter;
        let ulWidth = tabitem.offsetWidth;
        if (liTarget < 0) {
          tab.scrollLeft = 0;
          return;
        }
        // winWidth(375) - ulWidth(436) =  -61
        if (liTarget < winWidth - ulWidth) {
          tab.scrollLeft = -(winWidth - ulWidth) + liWidth;
          return;
        }
        tab.scrollLeft = liTarget;
      }
    },
  },
  mounted() {
  	if (this.$deviceType){
		this.flex_type = 'left'
	}
  }
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 60px;
  background-color: rgba(242, 242, 242, 1);
  display: flex;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.header_tab {
  width: 100%;
  height: 60px;
  display: flex;
  flex-wrap: nowrap;
  // flex布局居中对齐;适配手机
  justify-content: v-bind(flex_type);
  overflow: scroll;
  // padding-left: 19px;
  // padding-right: 19px;
  // 平滑滚动
  transition: all 0.05s linear;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.header_tab::-webkit-scrollbar {
  display: none;
}
ul {
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  padding-left: 0;
  padding-right: 19px;
  height: 60px;
}
li {
  background-color: #ffffff;
  width: 150px;
  display: inline-block;
  height: 59px;
  line-height: 59px;
  padding: 0px 10px;
  margin-left: 5px;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}
.activeheader {
  font-size: 18px;
  color: rgba(75, 121, 2, 0.803921568627451);
  // font-weight: 700;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(72, 220, 141, 0.0) 0%,
    rgba(75, 121, 2, 0.803921568627451) 100%
  );
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    /* bottom: 0; */
    left: calc(50% - 52px);
    // background-color: #2b96ff;
    border-radius: 1px;
    height: 0.02rem;
    box-sizing: border-box;
    background-color: rgba(75, 121, 2, 0.803921568627451);
    position: absolute;
    left: 0;
    bottom: 0rem;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    transform-origin: 0 0;
  }
}

</style>